import { SpecialCategoryID } from '@shared/item-category/enums';
import { ServiceItem } from '@shared/item/models';

export class ItemForm {
  public id: number;
  public price: number;
  public count: number;
  public imageLink: string;
  public categoryID: number;
  public is_custom: number;
  public title: string;

  constructor(form?: Partial<ItemForm>) {
    const defaultValue: ItemForm = {
      id: undefined,
      price: undefined,
      count: 0,
      imageLink: '',
      categoryID: SpecialCategoryID.NO_CATEGORY,
      is_custom: 0,
      title: ''
    };

    Object.assign(this, defaultValue, form);
  }

  public static fromEntity = (item: ServiceItem, count?: number): ItemForm => ({
    id: item.id,
    price: item.price,
    count: count ?? (item.count || 1),
    imageLink: item.imageLink || '',
    categoryID: item.categoryID,
    is_custom: item.is_custom,
    title: item.title
  });
}
