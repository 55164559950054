export const configurationCommon = ({ apiDomain }: { apiDomain: string }) => {
  const apiURL = `https://${apiDomain}`;

  return {
    production: true,
    environment: 'local',
    api: {
      url: apiURL,
      allowedDomains: [
        apiDomain
      ],
      disallowedRoutes: [
        apiURL + '/auth/restore-password',
        apiURL + '/auth/forgot-password',
        apiURL + '/auth/token/check',
        apiURL + '/login'
      ]
    },
    language: {
      available: ['en'],
      default: 'en'
    },
    dateFormat: {
      date: 'MMM d, y, EEE',
      dateTime: 'MMM d, y, EEE, h:mm\u00a0a',
      medium: 'MMM d, y, h:mm\u00a0a',
      mediumDate: 'MMM d, y',
      weekDay: 'EEEE',
      weekDayNarrow: 'EEEEE'
    },
    breakpoints: {
      mobile: 767
    },
    sentry: {
      dsn: 'https://1319858a1c4346f3bf26955cfb304e85@o1249231.ingest.sentry.io/6414300'
    },
    verificationCodeRequestTimeout: 30,
    verificationCodeCheckAttempts: 2,
    googleApiKey: 'AIzaSyBtx8GpF-9dDSWD_IiypY-UZf_FbUXEyaM',
    mapboxToken: 'pk.eyJ1IjoiaGFtcGVyYXBwIiwiYSI6ImNra2ZoNDA1MTA0ZWQydmxheTMwaHNjZmwifQ.3lewv-htbUi0Dn6c9jctLg',
    intercomAppID: 'x9xv3zeg',
    stripeKey: 'pk_live_GORP7azx5sliZLdMXVMhPCzL',
    firebaseConfig :{
      apiKey: "AIzaSyBye0dSYgW7MQ9ezMhJCzM1kpr6p-4VQ5U",
      authDomain: "hamperapp-1490443482281.firebaseapp.com",
      databaseURL: "https://hamperapp-1490443482281.firebaseio.com",
      projectId: "hamperapp-1490443482281",
      storageBucket: "hamperapp-1490443482281.appspot.com",
      messagingSenderId: "1078883846776",
      appId: "1:1078883846776:web:2ab0d1cb889be662c7bb8d",
      locationId: 'us-central',
    },
    maxPickupDropoffDifference: 14,
    customerLinks: {
      faq: 'https://www.hamperapp.com/faq',
      appleStore: 'https://apps.apple.com/us/app/hamperapp-laundry-on-demand/id1129155186',
      googlePlay: 'https://play.google.com/store/apps/details?id=com.hamperapp.hamperapp',
      twitter: 'https://twitter.com/hamperapp',
      facebook: 'https://www.facebook.com/hamperapp',
      linkedin: 'https://www.linkedin.com/company/hamperapp',
      deleteForm: 'https://form.jotform.com/220887335852161',
      doNotSellMyInfoForm: 'https://form.jotform.com/222636713501045',
    },
    customerContacts: {
      email: 'info@hamperapp.com',
      phone: '(866)750-4741',
      phoneCode: '+1'
    },
    cardAuthorizationAmountLocal: 30,
    defaultLaundryID: 1,
    defaultPhoneCode: '1',
    googleTagManagerIDStaging:'GTM-5GXCKPQC',
    googleTagManagerIDProd:'GTM-PD8PLJF8',
    hotJarIdStg:'3765219',
    hotJarIdProd:'1193268',
    deepLink: 'https://hamperapp.page.link/app/',
    deepLinkParametr: '?ibi=RonasIT%2EHamperapp%2Dios2&isi=1129155186&apn=com%2Ehamperapp%2Ehamperapp&link=https%3A%2F%2Fwww%2Ehamperapp%2Ecom%3Fpromocode%3D',
    recaptcha: {
      siteKey: '6Ld8mQ4qAAAAANZraxbwEWS1vuPhWSrKM6AaJzUx',
    },
  };
};
