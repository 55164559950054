import { BrowserModule } from '@angular/platform-browser';
import {
  NgModule,
  DEFAULT_CURRENCY_CODE,
  APP_INITIALIZER,
} from '@angular/core';
import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';
import {
  TranslateCompiler,
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { WebpackTranslateLoader } from './app.translate.loader';
import { EffectsModule } from '@ngrx/effects';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AuthModule } from '@shared/auth';
import { UserModule } from '@shared/user';
import { ApiModule, DeviceTypeInterceptor } from '@shared/api';
import { NotificationModule } from '@shared/notification';
import { NgxMaskModule } from 'ngx-mask';
import { DialogModule } from '@shared/dialog';
import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressRouterModule } from 'ngx-progressbar/router';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { CurrencyPipe } from '@angular/common';
import { MenuModule } from '@shared/menu';
import { GeolocationModule } from '@shared/geolocation';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppFacade } from './app.facade';
import { BrowserStateInterceptor } from '@shared/transfer-state';
import { Observable } from 'rxjs';
import { configuration } from '@configurations';
import { CookieModule } from '@shared/cookie-service';
import { AuthEffects } from '@shared/auth/store';
import {
  MatLuxonDateModule,
  MAT_LUXON_DATE_ADAPTER_OPTIONS,
} from '@angular/material-luxon-adapter';
import { ClipboardModule } from '@shared/clipboard';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SHARE_BUTTONS_CONFIG } from 'ngx-sharebuttons';
import { shareButtonsConfig } from '@shared/share';
import { CookiePopupComponent } from '@shared/cookie-popup';
import { ErrorHandlingModule } from '@shared/error-handling';
import { WebviewModule } from '@shared/webview';
import { PaymentModule } from '@shared/payment';
import { ZipModule } from '@shared/zip';
import { RealtimeConnectionKeyInterceptor } from '@shared/realtime/interceptors';
import { RealtimeModule } from '@shared/realtime';
import {
  ForbiddenInterceptor,
  TokenExpiredInterceptor,
} from '@shared/auth/interceptors';
import { GoogleTagModule } from '@shared/google-tag';
import {HotjarModule} from '@shared/hotjar';
import {IconModule} from '@shared/icon';
import {SeoModule} from '@shared/seo';
import {NoInternetModule} from '@app/public/no-internet/no-internet.module';
import {PageModule} from '@shared/page';
import {BrazeModule} from '@shared/braze';
import {PromocodeModule} from '@shared/promocode';

function appInitializerFactory(
  translateService: TranslateService
): () => Observable<any> {
  return () => translateService.use(configuration.language.default);
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'hamperapp' }),
    BrowserAnimationsModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler,
      },
      loader: {
        provide: TranslateLoader,
        useClass: WebpackTranslateLoader,
      },
    }),
    EffectsModule.forRoot([AuthEffects]),
    StoreRouterConnectingModule.forRoot(),
    StoreModule.forRoot({
      router: routerReducer,
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 30,
      logOnly: false,
    }),
    AuthModule,
    ZipModule,
    UserModule,
    ApiModule,
    NotificationModule,
    NgxMaskModule.forRoot(),
    DialogModule,
    NgProgressModule,
    NgProgressRouterModule.withConfig({
      id: 'router-progressbar',
    }),
    MenuModule,
    GeolocationModule,
    MatLuxonDateModule,
    ClipboardModule,
    CookiePopupComponent,
    ErrorHandlingModule,
    WebviewModule,
    CookieModule,
    PaymentModule,
    RealtimeModule,
    GoogleTagModule,
    HotjarModule,
    BrazeModule,
    IconModule,
    SeoModule,
    NoInternetModule,
    PageModule,
    PromocodeModule
  ],
  providers: [
    AppFacade,
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'USD',
    },
    CurrencyPipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BrowserStateInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DeviceTypeInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RealtimeConnectionKeyInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService],
      multi: true,
    },
    {
      provide: MAT_LUXON_DATE_ADAPTER_OPTIONS,
      useValue: { useUtc: true },
    },
    {
      provide: SHARE_BUTTONS_CONFIG,
      useValue: shareButtonsConfig,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ForbiddenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenExpiredInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
