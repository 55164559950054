import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { REQUEST, RESPONSE } from '@nguniversal/express-engine/tokens';
import { Observable, tap } from 'rxjs';
import { Request, Response } from 'express';
import { CookieService } from '../cookie.service';

@Injectable({
  providedIn: 'root'
})
export class CookieInterceptor implements HttpInterceptor {
  constructor(
    @Optional() @Inject(REQUEST) private browserRequest: Request,
    @Optional() @Inject(RESPONSE) private browserResponse: Response,
    private cookieService: CookieService
  ) { }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.browserRequest) {
      request = request.clone({
        headers: request.headers.set('cookie', this.cookieService.cookieString)
      });
    }

    return next.handle(request)
      .pipe(
        tap((event) => {
          if (event instanceof HttpResponse && event.headers.has('set-cookie')) {
            this.browserResponse.setHeader('set-cookie', event.headers.getAll('set-cookie'));
          }
        })
      );
  }
}
