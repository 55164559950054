import {createEffect, ofType} from '@ngrx/effects';
import {Injectable} from '@angular/core';
import {Action} from '@ngrx/store';
import {forkJoin, Observable, of} from 'rxjs';
import {catchError, exhaustMap, filter, map, mergeMap, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {Router} from '@angular/router';
import {SetValueAction} from 'ngrx-forms';
import {PrivateServicesServicePageSelectors} from './selectors';
import {AppDialogService} from '@shared/dialog';
import {DropOffType, Order, PrepareOrderData} from '@shared/order';
import {TranslateService} from '@ngx-translate/core';
import {ConfirmationModalData, ConfirmationModalComponent} from '@shared/confirmation-modal';
import {
  User,
  UserAddressActions,
  UserRootActions,
  UserRootSelectors,
  UserService,
  UserZipSelectors
} from '@shared/user';
import {Promocode, PromocodeService} from '@shared/promocode';
import {Service} from '@shared/service';
import {HttpErrorResponse} from '@angular/common/http';
import {BaseServicePageEffects} from '@shared/base-service-page/store';
import {PrivateServicesServicePageActions} from './actions';
import {NotificationService} from '@shared/notification';
import {PaymentService} from '@shared/payment';
import {AppError, AppErrorCode} from '@shared/error-handling';
import {ZipService} from '@shared/zip';
import {PrivateServicesServicePageState} from './state';
import {Address} from '@shared/address';
import {ServicePreferenceOption} from '@shared/service-preference';
import {OrderItemsActions} from '@shared/order-items/store';
import {getSelectors} from "@ngrx/router-store";

@Injectable()
export class PrivateServicesServicePageEffects extends BaseServicePageEffects<PrivateServicesServicePageState> {
  public init$: Observable<[Action, string, Array<Service>, Order, User]> = createEffect(() =>
      this.actions$.pipe(
        ofType(PrivateServicesServicePageActions.init),
        withLatestFrom(
          this.store.select(PrivateServicesServicePageSelectors.code),
          this.store.select(PrivateServicesServicePageSelectors.services),
          this.store.select(UserRootSelectors.activeOrder),
          this.userService.profile$
        ),
        tap(([_, serviceCode, services, activeOrder, user]) => {
          if (!serviceCode || !activeOrder?.pickupAddress?.zipID && !user?.activeAddress?.zipID) {
            this.router.navigate(['/services']);
          } else if (!services?.length) {
            this.store.dispatch(PrivateServicesServicePageActions.loadZip({}));
          } else {
            this.store.dispatch(PrivateServicesServicePageActions.setService({serviceCode}));
          }
        })
      ),
    {dispatch: false}
  );

  public loadZip$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(PrivateServicesServicePageActions.loadZip),
      withLatestFrom(
        this.store.select(PrivateServicesServicePageSelectors.code),
        this.store.select(UserRootSelectors.activeOrder),
        this.userService.profile$
      ),
      switchMap(([{address}, serviceCode, activeOrder, user]) => this.zipService
        .get(
          address?.zipID || ((activeOrder?.serviceCode === serviceCode)
              ? (user.getAddress(activeOrder?.pickupAddress?.id) || activeOrder?.pickupAddress)?.zipID
              : user.activeAddress?.zipID
          ),
          {relations: ['services.delivery_prices', 'services.laundry', 'services.service.media', 'services.laundry_zips.zip']}
        )
        .pipe(
          mergeMap((zip) => [
            PrivateServicesServicePageActions.loadZipSuccess({zip}),
            PrivateServicesServicePageActions.setService({serviceCode, address})
          ]),
          catchError((response: HttpErrorResponse) => {
            this.errorHandlingService.handleHttpError(response, {
              translateKey: 'PRIVATE.SERVICES.SERVICE.TEXT_LOAD_ZIP_FAILURE'
            });

            return of(PrivateServicesServicePageActions.loadZipFailure({response}));
          })
        )
      )
    )
  );

  public setService$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(PrivateServicesServicePageActions.setService),
      withLatestFrom(
        this.store.select(PrivateServicesServicePageSelectors.laundryService),
        this.store.select(UserRootSelectors.activeOrder),
        this.userService.profile$,
        this.store.select(getSelectors().selectUrl)
      ),
      filter(([_, laundryService,activeOrder,profil,url]) => {
        if (!laundryService) {
          UserRootActions.clearCurrentOrders()
          if(url!=='/profile'){
            this.router.navigate(['/services']);
          }

        }

        return !!laundryService;
      }),
      map(([{address}, laundryService, activeOrder, user]) => PrivateServicesServicePageActions.prefillOrder({
        laundryService,
        orderDraft: (activeOrder?.serviceID === laundryService.parentID) ? activeOrder : null,
        user,
        address
      }))
    )
  );

  public prefillOrder$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(PrivateServicesServicePageActions.prefillOrder),
      switchMap(({orderDraft, laundryService, user, address}) => forkJoin([
        this.orderService.prepare({
          serviceID: laundryService.parentID,
          laundryID: laundryService.laundryID
        }),
        this.loadPromocode(orderDraft?.promocode?.code)
      ]).pipe(
        map(([initialData, promocode]) => PrivateServicesServicePageActions.prefillOrderSuccess({
          order: this.prefillOrder({initialData, promocode, orderDraft, laundryService, user, address})
        })),
        catchError((response) => {
          this.errorHandlingService.handleHttpError(response);

          return of(PrivateServicesServicePageActions.prefillOrderFailure({response}));
        })
      ))
    )
  );

  public calculateOrderFailure$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(PrivateServicesServicePageActions.calculateOrderFailure),
      withLatestFrom(
        this.store.select(PrivateServicesServicePageSelectors.order),
        this.store.select(PrivateServicesServicePageSelectors.isConfirming)
      ),
      filter(([_, order, isConfirming]) => !order?.id && !isConfirming),
      tap(([{response}]) => this.errorHandlingService.handleHttpError(response, {
        translateKey: 'PRIVATE.SERVICES.SERVICE.TEXT_GET_ORDER_COST_ERROR'
      })),
      filter(([{response}]) => response.error?.error),
      mergeMap(() => [
        UserRootActions.clearCurrentOrders(),
        PrivateServicesServicePageActions.init({})
      ])
    )
  );

  public handleAddressChange$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(PrivateServicesServicePageActions.setAddress),
      withLatestFrom(
        this.store.select(PrivateServicesServicePageSelectors.laundryService),
        this.store.select(UserZipSelectors.zip),
        this.store.select(UserRootSelectors.activeOrder)
      ),
      mergeMap(([{address}, laundryService, userZip, activeOrder]) => {
          if (activeOrder?.deliveryAddress?.zip.code === address.zipCode) {
            return [UserAddressActions.select({address}),]
          }
          if (!!laundryService && !!laundryService.hasZipCode(address.zipCode)) {
            return [
              PrivateServicesServicePageActions.loadZip({address}),
              UserAddressActions.select({address}),

            ]
          } else {
            return [
              PrivateServicesServicePageActions.loadZip({address}),
              UserAddressActions.select({address}),
              UserRootActions.clearCurrentOrders()
            ]
          }

        }
      )
    )
  );

  public handleCurrentAddressUpdate$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAddressActions.upsertSuccess),
      withLatestFrom(this.store.select(PrivateServicesServicePageSelectors.order)),
      filter(([{address}, order]) => address.id === order?.pickupAddress?.id),
      map(([{address}]) => PrivateServicesServicePageActions.setAddress({address}))
    )
  );

  public orderChanged$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SetValueAction.TYPE),
      withLatestFrom(this.store.select(PrivateServicesServicePageSelectors.formState)),
      filter(([action, formState]) => (action as SetValueAction<string>)?.controlId?.startsWith(formState.id)),
      map(() => PrivateServicesServicePageActions.changeService({}))
    )
  );

  public changeService$: Observable<[Action, Order, Order]> = createEffect(() =>
      this.actions$.pipe(
        ofType(PrivateServicesServicePageActions.changeService),
        withLatestFrom(
          this.store.select(PrivateServicesServicePageSelectors.currentOrder),
          this.store.select(UserRootSelectors.activeOrder)
        ),
        tap(([{onConfirm}, order, activeOrder]) => {
          if (!!activeOrder && activeOrder?.serviceID !== order?.serviceID && activeOrder.items.length > 0) {
            this.dialogService.openComponent<ConfirmationModalData>(
              ConfirmationModalComponent,
              {
                data: {
                  title: 'PRIVATE.SERVICES.SERVICE.TEXT_CHANGE_SERVICE',
                  text: this.getChangeServiceText(activeOrder, order),
                  cancelButtonText: 'PRIVATE.SERVICES.SERVICE.BUTTON_NO',
                  confirmButtonText: 'PRIVATE.SERVICES.SERVICE.BUTTON_YES_CHANGE',
                  action: (dialogID: string) => {
                    this.dialogService.closeByID(dialogID);
                    this.store.dispatch(UserRootActions.updateCurrentOrders({order, setActive: true}));
                    onConfirm?.();
                  },
                  onCancel: () => this.store.dispatch(PrivateServicesServicePageActions.setService({serviceCode: order.serviceCode}))
                }
              }
            );
          } else {
            if (!activeOrder || activeOrder.items.length === 0) {
              this.store.dispatch(UserRootActions.updateCurrentOrders({order, setActive: true}));
            }
            onConfirm?.();
          }
        })
      ),
    {dispatch: false}
  );

  public startOrderConfirmation$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(PrivateServicesServicePageActions.startOrderConfirmation),
      withLatestFrom(
        this.store.select(PrivateServicesServicePageSelectors.currentOrder),
        this.userService.profile$
      ),
      filter(([{paymentSystem}, order]) => {
        if (!order.paymentSystem) {
          this.store.dispatch(PrivateServicesServicePageActions.confirmOrder({order}));

          return false;
        }

        return true;
      }),
      exhaustMap(([{paymentSystem}, order, user]) => this.paymentService
        .requestPaymentMethod(order.paymentSystem)
        .pipe(
          map((paymentMethod) => PrivateServicesServicePageActions.confirmOrder({
            order: new Order({...order, paymentMethodStripeID: paymentMethod.id})
          })),
          catchError((error: AppError) => {
            if (error.code === AppErrorCode.PAYMENT_SYSTEM_NOT_AVAILABLE) {
              const relatedUserMethod = (user.activePaymentMethod?.paymentSystem === order.paymentSystem)
                ? user.activePaymentMethod
                : null;

              if (relatedUserMethod) {
                return of(PrivateServicesServicePageActions.confirmOrder({
                  order: new Order({...order, paymentMethodID: relatedUserMethod.id})
                }));
              }

              this.notificationService.error('PRIVATE.SERVICES.SERVICE.TEXT_PAYMENT_SYSTEM_NOT_AVAILABLE');
            } else if (error.code === AppErrorCode.STRIPE_PAYMENT_METHOD_NOT_PROVIDED) {
              this.notificationService.error('PRIVATE.SERVICES.SERVICE.TEXT_PAYMENT_METHOD_NOT_PROVIDED');
            } else {
              this.notificationService.error('PRIVATE.SERVICES.SERVICE.TEXT_PAYMENT_SYSTEM_NOT_AVAILABLE');
            }

            return of(PrivateServicesServicePageActions.confirmOrderFailure({response: error}));
          })
        )
      )
    )
  );

  public confirmOrder$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(PrivateServicesServicePageActions.confirmOrder),
      exhaustMap(({order}) => this.orderService
        .create(order)
        .pipe(
          tap(() => this.dialogService.closeAll()),
          mergeMap((newOrder) => [
            PrivateServicesServicePageActions.confirmOrderSuccess({order: newOrder}),
            UserRootActions.clearCurrentOrders(),
            UserRootActions.refreshProfile(),
            OrderItemsActions.resetState()
          ]),
          catchError((response: HttpErrorResponse) => {
            this.errorHandlingService.handleHttpError(response, {
              translateKey: 'PRIVATE.SERVICES.SERVICE.TEXT_CONFIRM_ORDER_FAILURE'
            });

            return of(PrivateServicesServicePageActions.confirmOrderFailure({response}));
          })
        )
      )
    )
  );

  constructor(
    private router: Router,
    private dialogService: AppDialogService,
    private translateService: TranslateService,
    private userService: UserService,
    private promocodeService: PromocodeService,
    private notificationService: NotificationService,
    private paymentService: PaymentService,
    private zipService: ZipService
  ) {
    super(
      PrivateServicesServicePageActions,
      PrivateServicesServicePageSelectors
    );
  }

  private getChangeServiceText(activeOrder: Order, currentOrder: Order): string {
    const basicText = this.translateService.instant('PRIVATE.SERVICES.SERVICE.TEXT_CHANGE_SERVICE_INFO');
    const oldCount = activeOrder?.itemsCount;
    const newCount = currentOrder?.itemsCount;
    if (oldCount > 0 && newCount > 0) {
      return `${basicText} ${this.translateService.instant('PRIVATE.SERVICES.SERVICE.TEXT_SURE_TO_CHANGE_WITH_ITEMS', {
        oldCount,
        oldService: activeOrder.service.name,
        newService: currentOrder.service.name
      })}`;
    } else {
      return `${basicText} ${this.translateService.instant('PRIVATE.SERVICES.SERVICE.TEXT_SURE_TO_CHANGE')}`;
    }
  }

  private loadPromocode(orderPromocode: string | null): Observable<Promocode> {
    return ((orderPromocode) ? this.promocodeService.check({promocode: orderPromocode}) : this.promocodeService.loadLast())
      .pipe(
        map((loadedPromocode) => loadedPromocode?.id ? loadedPromocode : null),
        catchError(() => of(null))
      );
  }

  private prefillOrder({initialData, promocode, orderDraft, user, address, laundryService}: {
    initialData: PrepareOrderData;
    promocode: Promocode;
    orderDraft: Order | null;
    user: User;
    address: Address;
    laundryService: Service;
  }): Order {
    const orderAddress = user.activeAddress;
    const isTimeValid = initialData.pickupInterval && +orderDraft?.pickupTime >= +initialData.pickupInterval.from;
    const pickupTime = (isTimeValid) ? orderDraft.pickupTime : initialData.pickupInterval?.from;
    const deliveryTime = (isTimeValid) ? orderDraft.deliveryTime : initialData.deliveryInterval?.from;

    return new Order({
      ...orderDraft,
      pickupTime,
      pickupTo: pickupTime.plus({minutes: laundryService.intervalDuration}),
      deliveryTime,
      deliveryTo: deliveryTime.plus({minutes: laundryService.intervalDuration}),
      pickupAddress: orderAddress,
      deliveryAddress: orderAddress,
      laundryService,
      promocode,
      promocodeID: promocode?.id,
      isSaveOptions: initialData.wereAllClientOptionsSpecified,
      options: this.prefillOrderOptions({savedOptions: orderDraft?.options, clientOptions: initialData.options}),
      paymentMethodID: (!orderDraft)
        ? user.activePaymentMethod?.id
        : user.getPaymentMethod(orderDraft.paymentMethodID)?.id,
      pounds: laundryService.hasPricePerPound ? ((orderDraft?.pounds > laundryService.minimumOrderWeight)
        ? orderDraft.pounds
        : laundryService.minimumOrderWeight) : 0,
      dropOffType: orderDraft?.dropOffType || user.dropOffType || DropOffType.AT_THE_DOOR
    });
  }

  private prefillOrderOptions({savedOptions, clientOptions}: {
    savedOptions: Array<ServicePreferenceOption> | null;
    clientOptions: Array<ServicePreferenceOption>;
  }): Array<ServicePreferenceOption> {
    if (!savedOptions) {
      return clientOptions;
    }

    return clientOptions.map((clientOption) => {
      const savedOption = savedOptions.find((option) => option.servicePreferenceID === clientOption.servicePreferenceID);

      return savedOption || clientOption;
    });
  }
}
