<div *ngVar="'SHARED.NAVBAR.' as translations" class="navbar">
  <content *ngVar="user$ | async as user" size="large" class="navbar-content">
    <ng-container *ngVar="isVisibleWhiteDesign$ |async as isVisibleWhiteDesign">
    <ng-container *ngVar="isRedirectToCity$ |async as isRedirectToCity">
    <button *ngIf="!isWebView || (isWebView && user) " data-test="menu-trigger" (click)="menuTriggerClicked()" class="button navbar-menu-trigger">
      <icon type="menu" [color]="!isVisibleWhiteDesign ?'black':'white'"></icon>
    </button>
    <logo *ngIf="!isVisibleWhiteDesign" variant='primary' class='navbar-mobile-logo'></logo>
    <logo *ngIf="isVisibleWhiteDesign" variant='white' class='navbar-mobile-logo'></logo>
    <div class="navbar-nav">
      <div class="navbar-nav-item navbar-geolocation">
        <geolocation-view *ngIf="!isVisibleWhiteDesign" [isRedirectToCity]="isRedirectToCity"></geolocation-view>
      </div>
      <a routerLink="/profile" *ngIf="user" class="navbar-user navbar-nav-item">
        <div class="navbar-user-avatar">
          <avatar [src]="user.avatarLink"></avatar>
        </div>
        <span class="navbar-user-name">{{ user?.nameWithInitials }}</span>
      </a>
      <div *ngIf="!user" class="navbar-nav-item navbar-nav-item-auth">
        <app-link routerLink="/login" [color]="(isVisibleWhiteDesign$ |async)?'white':'grays-dark'">
          <icon type="profile" color="default"></icon>
          {{ translations + 'LINK_LOGIN' | translate }}
        </app-link>
      </div>
      <ng-container *ngVar="activeOrder$ | async as activeOrder">
        <div *ngIf="!user" class="navbar-nav-item navbar-nav-item-auth">
          <a *ngIf="activeOrder?.hasItems; else noItems" routerLink="/register">
            <app-button variant="default" size="smaller">
              {{ translations + 'LINK_SIGNUP' | translate }}
            </app-button>
          </a>
          <ng-template #noItems>
            <a routerLink="/register">
              <app-button variant="primary" size="smaller" customClass="flare">
                {{ translations + 'LINK_SIGNUP' | translate }}
              </app-button>
            </a>
          </ng-template>
        </div>
        <ng-container *ngIf="isVisibleWhiteDesign" >
          <div class="visible-mobile navbar-white-sign">
          <a routerLink="/register" class="navbar-white-sign-login">
            <app-button variant="primary" size="smaller"  customClass="flare">
              {{ translations + 'LINK_SIGNUP' | translate }}
            </app-button>
          </a>
          <a routerLink="/login">
            <app-button variant="purple" size="smaller" customClass="flare">
              {{ translations + 'LINK_SIGNUP_SPACE' | translate }}
            </app-button>
          </a>
          </div>
        </ng-container>
        <div *ngIf="activeOrder?.hasItems" class="navbar-nav-item">
          <a [routerLink]="'/services/' + activeOrder.serviceCode" class="button navbar-bag">
            <icon type="shopping-bag" color="white"></icon>
            {{ (activeOrder.items | itemsCount).toString() }}
          </a>
        </div>
      </ng-container>
    </div>
    <div class="navbar-geolocation navbar-geolocation-mobile" *ngIf="!isVisibleWhiteDesign">
      <geolocation-view></geolocation-view>
    </div>
    </ng-container>
    </ng-container>
  </content>
</div>
