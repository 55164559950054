import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, UrlTree} from '@angular/router';
import {PromocodeService} from '@shared/promocode';
import {catchError, exhaustMap, map, withLatestFrom} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {AppDialogService} from '@shared/dialog';
import {PromocodeFieldAppliedModalComponent} from '@shared/promocode/components/applied-modal';
import {getSelectors} from '@ngrx/router-store';
import {AuthService} from '@shared/auth';
import {Store} from '@ngrx/store';
import type {AppState} from '@shared/store';
import {HttpErrorResponse} from '@angular/common/http';
import {ErrorHandlingService} from '@shared/error-handling';
import {NotificationService} from '@shared/notification';
import {isPlatformBrowser} from "@angular/common";

@Injectable()
export class PromocodeGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthService,
    private store: Store<AppState>,
    private dialogService: AppDialogService,
    private promocodeService: PromocodeService,
    private errorHandlingService: ErrorHandlingService,
    private notificationService: NotificationService,
    @Inject(PLATFORM_ID) private platformID: Object,
  ) {
  }

  public canActivate(route: ActivatedRouteSnapshot): Observable<UrlTree | boolean> {

    if (route.queryParamMap.has('promocode')) {
      return this.authService.isAuthenticated$
        .pipe(
          withLatestFrom(
            this.store.select(getSelectors().selectUrl)
          ),
          exhaustMap(([isAuthenticated, currentURL]) => {
            if (!isAuthenticated) {
              this.router.navigateByUrl('/login')
              localStorage.setItem('promoCodeForActivateLoginMode', route.queryParamMap.get('promocode'));
              return of(true);
            }

            return this.promocodeService.activate({promocode: route.queryParamMap.get('promocode')})
              .pipe(
                map((promocode) => {
                  if (isPlatformBrowser(this.platformID)) {
                    this.dialogService.openComponent(PromocodeFieldAppliedModalComponent, {data: promocode});
                    this.router.navigateByUrl('/services')
                  }
                  return true
                }),
                catchError((response: HttpErrorResponse) => {
                  this.errorHandlingService.handleHttpError(response);
                  return of(true)
                })
              );
          })
        );
    }else{
      return  of(true)
    }
  }
}
