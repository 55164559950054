import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { BaseServicePageActions } from '@shared/base-service-page/store/actions';
import { AppError } from '@shared/error-handling';
import { Address } from '@shared/address';
import { Order } from '@shared/order';
import { Promocode } from '@shared/promocode';
import { Zip } from '@shared/zip';
import { PrivateServicesServicePageState } from './state';
import { Service } from '@shared/service';
import { User } from '@shared/user';
import {PaymentSystem} from '@shared/payment';

class Actions extends BaseServicePageActions<PrivateServicesServicePageState> {
  public init = createAction(
    `[${this.stateName}] Init`,
    props<{ code?: string }>()
  );

  public loadZip = createAction(
    `[${this.stateName}] Load Zip`,
    props<{ address?: Address }>()
  );

  public loadZipSuccess = createAction(
    `[${this.stateName}] Load Zip Success`,
    props<{ zip: Zip }>()
  );

  public loadZipFailure = createAction(
    `[${this.stateName}] Load Zip Failure`,
    props<{ response?: HttpErrorResponse }>()
  );

  public setService = createAction(
    `[${this.stateName}] Set Service`,
    props<{ serviceCode: string; address?: Address }>()
  );

  public prefillOrder = createAction(
    `[${this.stateName}] Prefill Order`,
    props<{ orderDraft: Order | null; laundryService: Service; user: User; address: Address }>()
  );

  public prefillOrderSuccess = createAction(
    `[${this.stateName}] Prefill Order Success`,
    props<{ order: Order }>()
  );

  public prefillOrderFailure = createAction(
    `[${this.stateName}] Prefill Order Failure`,
    props<{ response: HttpErrorResponse }>()
  );

  public setPromocode = createAction(
    `[${this.stateName}] Set Promocode`,
    props<{ promocode: Promocode }>()
  );

  public changeService = createAction(
    `[${this.stateName}] Change Service`,
    props<{ onConfirm?: () => void }>()
  );

  public setAddress = createAction(
    `[${this.stateName}] Set Address`,
    props<{ address: Address }>()
  );

  public startOrderConfirmation = createAction(
    `[${this.stateName}] Start Order Confirmation`,
    props<{ paymentSystem?: PaymentSystem }>()
  );

  public confirmOrder = createAction(
    `[${this.stateName}] Confirm Order`,
    props<{ order: Order }>()
  );

  public confirmOrderSuccess = createAction(
    `[${this.stateName}] Confirm Order Success`,
    props<{ order: Order }>()
  );

  public confirmOrderFailure = createAction(
    `[${this.stateName}] Confirm Order Failure`,
    props<{ response: HttpErrorResponse | AppError }>()
  );

  public openNextStage = createAction(
    `[${this.stateName}] Open Next Stage`
  );

  public openPreviousStage = createAction(
    `[${this.stateName}] Open Previous Stage`
  );

  public changeInformationShowedMode = createAction(
    `[${this.stateName}] Change information showed mode`,
    props<{ value: boolean }>()
  );

  constructor() {
    super('privateServicesServicePage');
  }
}

export const PrivateServicesServicePageActions = new Actions();
