<div class="service-tabs-content">
  <p class="service-tabs-title">Services</p>
  <div *ngVar="location$ | async as location" #tabs class="service-tabs hidden-scroll">
    <a *ngFor="let service of services; trackByProperty: 'id'"
       [routerLink]="service.serviceCode | map : buildRouterLink : location" routerLinkActive="current"
       (isActiveChange)="$event && serviceActivated(service)" class="service-tabs-tab" type="button">
      {{ service.serviceName }}
    </a>
    <a  *ngIf="isShowCommercial" [routerLink]="!location?.code?'/commercial':'/commercial/laundry-services/city/'+location.code"   class="service-tabs-tab" type="button">
    Commercial Laundry
  </a>
  </div>

</div>
