import {NgModule} from '@angular/core';
import {configuration} from '@configurations';
import {NgxHotjarModule} from "ngx-hotjar";

@NgModule({
  imports: [
    NgxHotjarModule.forRoot(configuration.environment === 'production'? configuration.hotJarIdProd:  configuration.hotJarIdStg)
  ]
})
export class HotjarModule {}
