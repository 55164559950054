import { NgModule } from '@angular/core';
import { configuration } from '@configurations';

@NgModule()
export class BrazeModule {
  constructor() {
    if (configuration.environment === 'production') {
      import(
        "@braze/web-sdk"
        ).then(({ initialize, automaticallyShowInAppMessages, requestPushPermission }) => {
        initialize(configuration.brazeKey, {
          baseUrl: configuration.brazeUrl,
          enableLogging: true,
        });
        automaticallyShowInAppMessages();
        requestPushPermission();
      });
    }
  }
}
