import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  standalone: true,
  selector: 'page-side',
  templateUrl: 'page-side.html',
  styleUrls: ['page-side.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageSideComponent { }
