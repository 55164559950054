import { Injectable } from '@angular/core';
import {User, UserZipActions} from '@shared/user';
import { AuthService as CommonAuthService } from '@ronas-it/angular-common';
import { Observable } from 'rxjs';
import { ApiService } from '@shared/api';
import {
  AuthResponse,
  CheckSocialTokenRequest,
  ForgotPasswordRequest,
  RegisterRequest,
  RegisterResponse,
  RestorePasswordRequest
} from './models';
import { Store } from '@ngrx/store';
import type { AppState } from '@shared/store';
import { CookieService } from '@shared/cookie-service';
import { map, tap } from 'rxjs/operators';
import { instanceToPlain, plainToInstance } from 'class-transformer';
import { AuthActions } from './store';
import { configuration } from '@configurations';
import { FirebaseAuthService } from '@shared/firebase-auth';
import { ConnectionType } from './enums';
import { SocialAuthData } from '@shared/firebase-auth/models';
import { pickBy } from 'lodash';
import { ValidateRequest } from './models/validate-request';

@Injectable()
export class AuthService extends CommonAuthService<User> {
  protected override apiService: ApiService;
  protected override cookieService: CookieService;

  private socialAuthConfig: Record<ConnectionType, () => Observable<SocialAuthData>>;

  constructor(
    protected store: Store<AppState>,
    protected firebaseAuthService: FirebaseAuthService
  ) {
    super();
    this.socialAuthConfig = {
      [ConnectionType.APPLE]: () => this.firebaseAuthService.loginWithApple(),
      [ConnectionType.GOOGLE]: () => this.firebaseAuthService.loginWithGoogle(),
      [ConnectionType.FACEBOOK]: () => this.firebaseAuthService.loginWithFacebook()
    };
  }

  public override manuallyAuthorize(authResponse: AuthResponse, remember: boolean = true): Observable<AuthResponse> {
    return super.manuallyAuthorize(authResponse, remember).pipe(
      tap(() => {
        if (configuration.environment === 'local') {
          this.cookieService.put('token', authResponse.token, { expires: this.cookiesExpiresDate,domain:'localhost' });
        }
      })
    );
  }


  public override setIsAuthenticated(remember?: boolean): void {
    super.setIsAuthenticated(remember);
    this.store.dispatch(AuthActions.authorized());
  }

  public register(request: Partial<RegisterRequest>): Observable<RegisterResponse> {
    const requestBody = new RegisterRequest(request);

    return this.apiService.post<RegisterResponse>('/register', pickBy(instanceToPlain(requestBody)))
      .pipe(
        map((response) => plainToInstance(RegisterResponse, response))
      );
  }

  public checkSocialToken(request: CheckSocialTokenRequest): Observable<void> {
    const requestBody = new CheckSocialTokenRequest(request);

    return this.apiService.post('/auth/social/check', instanceToPlain(requestBody));
  }

  public forgotPassword(request: Partial<ForgotPasswordRequest>): Observable<void> {
    const requestBody = new ForgotPasswordRequest(request);

    return this.apiService.post('/auth/forgot-password', instanceToPlain(requestBody));
  }

  public restorePassword(request: Partial<RestorePasswordRequest>): Observable<void> {
    const requestBody = new RestorePasswordRequest(request);

    return this.apiService.post('/auth/restore-password', instanceToPlain(requestBody));
  }

  public signInSocialMedia(socialMedia: ConnectionType): Observable<SocialAuthData> {
    return this.socialAuthConfig[socialMedia]();
  }

  public signOutSocialMedia(): Promise<void> {
    return this.firebaseAuthService.signOut();
  }

  public logout(): Observable<void> {
    return this.apiService.post('/auth/logout');
  }

  public override unauthorize(options?: {
    disableRedirect: boolean;
  }): void {
    super.unauthorize();
    this.clearPersonalStore();
    this.store.dispatch(AuthActions.unauthorized());
    this.firebaseAuthService.signOut();
    this.store.dispatch(UserZipActions.clearZip());
    if (!options?.disableRedirect) {
      this.router.navigate([this.authConfig.unauthenticatedRoute]);
    }
  }

  public clearPersonalStore(): void {
    this.cookieService.removeAllNotStartsWith('common_');
    localStorage.clear();
    sessionStorage.clear();
  }

  public validate(request: Partial<ValidateRequest>): Observable<void> {
    const requestBody = new ValidateRequest(request);

    return this.apiService.post('/auth/validate', instanceToPlain(requestBody));
  }
  loginRoureAction(redirectRoute:string){
    var promo=localStorage.getItem('promoCodeForActivateLoginMode');
    if(promo){
      localStorage.removeItem('promoCodeForActivateLoginMode')
      this.router.navigate(['/services'], {
        queryParams: {promocode:promo},
      });
    }else if(redirectRoute) {
      this.router.navigate(['/services'], {
        queryParams: {redirectRoute: redirectRoute},
      });
    }else {
      this.router.navigate([redirectRoute || '/services']);
    }
  }
}
