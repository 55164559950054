import { NgModule } from '@angular/core';
import { configuration } from '@configurations';
import { GoogleTagManagerModule, GoogleTagManagerService } from 'angular-google-tag-manager';

@NgModule({
  imports: [
    GoogleTagManagerModule.forRoot({
      id:  configuration.environment === 'production'? configuration.googleTagManagerIDProd:  configuration.googleTagManagerIDStaging
    })
  ]
})
export class GoogleTagModule {
  constructor(
    private gtmService: GoogleTagManagerService
  ) {

    if (configuration.environment === 'production' || configuration.environment === 'staging') {
      this.gtmService.addGtmToDom();
    }
  }
}
