import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  standalone: true,
  selector: 'page-title',
  templateUrl: 'title.html',
  styleUrls: ['title.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageTitleComponent { }
