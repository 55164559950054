import { configuration } from '@configurations';
import { Gender } from '@shared/user';
import {UserType} from "@shared/user-type-modal";

export class RegisterForm {
  public firstName: string;
  public lastName: string;
  public email: string;
  public phone: string;
  public phoneCode: string;
  public password: string;
  public confirmPassword: string;
  public gender: Gender | null;
  public genderOther: string | null;
  public birthdate: string;
  public account_type: UserType | undefined;

  constructor() {
    this.firstName = '';
    this.lastName = '';
    this.email = '';
    this.phone = '';
    this.phoneCode = configuration.defaultPhoneCode;
    this.password = '';
    this.confirmPassword = '';
    this.gender = null;
    this.genderOther = null;
    this.birthdate = '';
    this.account_type = undefined
  }
}
