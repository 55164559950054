import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponseBase } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable()
export class ServerStateInterceptor implements HttpInterceptor {
  constructor(
    private transferState: TransferState
  ) { }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.method !== 'GET') {
      return next.handle(request);
    }

    return next
      .handle(request)
      .pipe(
        tap((response) => this.handleResponse(response, request)),
        catchError((response) => {
          this.handleResponse(response, request);

          return throwError(() => response);
        })
      );
  }

  private handleResponse(response: any, request: HttpRequest<any>): void {
    if (response instanceof HttpResponseBase) {
      const stateKey = makeStateKey<Array<HttpResponseBase>>(request.urlWithParams);
      const savedResponses = this.transferState.get(stateKey, []);
      savedResponses.unshift(response);

      this.transferState.set(stateKey, savedResponses);
    }
  }
}
