import { configurationCommon } from './configuration.common';

const commonConfig = configurationCommon({
  apiDomain: 'back-api.hamperapp.com'
});

export const configuration = {
  ...commonConfig,
  production: true,
  environment: 'production',
  cardAuthorizationAmount: 4.99,
  brazeKey: '022b9340-0549-4466-9f26-e46f647e8485',
  brazeUrl: 'sdk.iad-07.braze.com',
  firebaseConfig : {
    apiKey: "AIzaSyBye0dSYgW7MQ9ezMhJCzM1kpr6p-4VQ5U",
    authDomain: "hamperapp-1490443482281.firebaseapp.com",
    databaseURL: "https://hamperapp-1490443482281.firebaseio.com",
    projectId: "hamperapp-1490443482281",
    storageBucket: "hamperapp-1490443482281.appspot.com",
    messagingSenderId: "1078883846776",
    appId: "1:1078883846776:web:d585b0d30333da2bc7bb8d",
    locationId: 'us-central',
  },
  deepLink: 'https://hamperapp.page.link/',
  deepLinkParametr: '?ibi=RonasIT%2EHamperapp%2Dios2&isi=1129155186&apn=com%2Ehamperapp%2Ehamperapp&link=https%3A%2F%2Fwww%2Ehamperapp%2Ecom%3Fpromocode%3D',

};
