import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../auth.service';

@Injectable()
export class UnauthenticatedGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService
  ) { }

  public canActivate(): Observable<UrlTree | boolean> {
    return this.authService.isAuthenticated$
      .pipe(
        map((isAuthenticated) => {
          if (isAuthenticated) {
            return this.router.parseUrl('/services');
          }

          return true;
        })
      );
  }
}
