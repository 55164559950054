import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  standalone: true,
  selector: 'mobile-controls',
  templateUrl: 'mobile-controls.html',
  styleUrls: ['mobile-controls.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileControlsComponent { }
