import { Actions, createEffect, ofType } from '@ngrx/effects';
import type { AppState } from '@shared/store';
import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { SeoService } from '../seo.service';
import { catchError, filter, map, Observable, of, switchMap, tap, withLatestFrom } from 'rxjs';
import { SeoActions } from './actions';
import {SeoPage, SeoPageHeadingTags, SeoPageService, SeoPageTag, SeoPageTagType, SeoPageType} from '@shared/seo-page';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { getSelectors } from '@ngrx/router-store';
import {ErrorHandlingService} from "@shared/error-handling";

@Injectable()
export class SeoEffects {
  public resetState$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SeoActions.resetState),
      tap(() => this.resetSEO())
    ),
    { dispatch: false }
  );

  public loadSettings$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SeoActions.tryLoadSettings),
      tap(() => this.resetSEO()),
      filter(({ request, defaultSettings }) => !!request?.url && (!!request?.type || !!defaultSettings)),
      tap(() => this.store.dispatch(SeoActions.loadSettings())),
      withLatestFrom(
        this.store.select(getSelectors().selectUrl)
      ),
      switchMap(([{ request, onSuccess, onFailure, defaultSettings }, initialURL]) =>
        ((!!request?.type) ? this.seoPageService.getByURL(request) : of(defaultSettings))
          .pipe(
            withLatestFrom(
              this.store.select(getSelectors().selectUrl)
            ),
            filter(([_, currentURL]) => initialURL === currentURL),
            tap(([settings]) => {
              if (settings?.deletedAt && !settings?.isDefault) {
                if(settings.type===SeoPageType.COMMON){
                  throw new HttpErrorResponse({ status: HttpStatusCode.BadRequest });

                }else{
                  throw new HttpErrorResponse({ status: HttpStatusCode.NotFound });

                }
              }
            }),
            tap(([settings]) => {
              if(settings?.deletedAt){
                defaultSettings= {...defaultSettings,codeHead:settings.codeHead+''+defaultSettings.codeHead,isSanDiego:false}
                if (!defaultSettings) {
                  this.seoService.initSettings(defaultSettings);
                }
                onSuccess?.(defaultSettings);
                if(settings.type===SeoPageType.COMMON){
                  throw new HttpErrorResponse({ status: HttpStatusCode.BadRequest });

                }else{
                  throw new HttpErrorResponse({ status: HttpStatusCode.NotFound });

                }
              }else {
                if(request.url==='/services/wash-and-fold-laundry-services/city/san-diego-ca'){
                  settings.url=request.url;
                   settings=this.initSpecialSetting(settings)
                }
                onSuccess?.(settings);
                this.seoService.initSettings(settings);
              }
            }),
            map(([settings]) => SeoActions.loadSettingsSuccess({ settings })),
            catchError((response: HttpErrorResponse) => {
              if(response.status===HttpStatusCode.NotFound){
                this.errorHandlingService.handleHttpError(response);
              }
              onFailure?.(response);
              if (defaultSettings) {
                this.seoService.initSettings(defaultSettings);
              }
              return of(SeoActions.loadSettingsFailure({ response, settings: defaultSettings }));
            })
          )
      )
    )
  );
    initSpecialSetting(settings:SeoPage){
           settings.title='Laundry service in San Diego, CA - Hamperapp'
           settings.seoTags=[new SeoPageTag({id:2165, type:SeoPageTagType.SEO, name:'description', content:'Join the laundry revolution in San Diego with Hamperapp! Experience hassle-free laundry service with free pickup & delivery, real-time tracking, and a variety of cleaning options. Schedule your first pickup today and enjoy fresh, clean clothes without lifting a finger. #LaundryServiceSanDiego'})]
      settings.tags.heading1=new SeoPageHeadingTags({title:'Laundry service in San Diego, CA',description:'Try Hamperapp, the best laundry service in San Diego, CA! We pick up and deliver your dry cleaning and laundry for free. You can easily set up your service with just a few clicks. Plus, you can track your laundry from when it\'s dirty until it\'s clean, whether you need it every day, once a week, or once a month. \n \n Choose from lots of cleaning options at your local laundromat. Then, sit back and relax. We\'ll bring your clean laundry right to your doorstep. Hamperapp is the most convenient and trustworthy way to do your laundry in San Diego, CA.\n'})
      settings.tags.heading2=new SeoPageHeadingTags({title:'Laundry pick up service in San Diego',description:'Hamperapp is the top choice in San Diego, CA, for getting your laundry done without leaving your house. We work with a bunch of laundry experts all over the city to make sure you can get your laundry done your way. \n \n All you have to do is tap a few buttons on your phone or computer, and we\'ll arrange for someone to pick up your laundry. While you\'re busy with other stuff, our reliable partners will wash, dry, and fold your clothes. You can even watch where your order is in real-time and talk to our customer service any time, day or night. \n \n Trust Hamperapp to bring back your clothes clean, fresh, and neatly folded, right to your doorstep.\n'})
      settings.tags.heading3=new SeoPageHeadingTags({title:'Wash and fold laundry service in San Diego',description:'On-demand Laundry service platform, seven days a week. Schedule a pickup Today!'})
      settings.tags.heading4=new SeoPageHeadingTags({title:'Laundromat delivery service in San Diego CA',description:'If you\'re looking for a laundromat near you in San Diego that also offers free pick-up and delivery, check out Hamperapp! To find out how much it costs for laundry services with Hamperapp in San Diego, CA, it\'s best to go to their website. Once there, just pick your zip code or talk to their friendly customer support team. They will help you know the exact prices for their laundromat delivery service in San Diego.'})
      settings.tags.heading5=new SeoPageHeadingTags({title:'Laundry service delivery and pickup in San Diego',description:'Need to wash your clothes but don\'t want to do it yourself in San Diego? Check out Hamperapp!\n \n' +
          'It\'s a super easy way to get your laundry done without leaving home. All you need to do is tap a few buttons on your phone or computer to start.\n \n' +
          'We work with lots of laundry places in San Diego that we trust. You can choose to have your laundry picked up every day, once a week, or even once a month.\n \n' +
          'Just tell us when to come, and our partners will take care of washing and folding your clothes. You can even watch where your laundry is on its journey back to you.\n \n' +
          'Plus, if you need any help, our team is here to answer your questions any time, day or night. When your clothes are clean, we\'ll bring them straight to your doorstep. Give Hamperapp a try for the easiest way to handle your laundry in San Diego!\n'})
      settings.tags.heading6=new SeoPageHeadingTags({title:'Pick Up Laundry Service San Diego',description:'Looking for a way to get your laundry done without any hassle in San Diego? Say hello to Hamperapp! We make washing your clothes easy and quick. Just use your phone or computer to tell us when you need your laundry picked up. We work with the best laundromats around town, so you can trust your clothes will be in good hands.\n \n' +
          'No matter if you need your clothes washed once a day, once a week, or once a month, we can help. Just schedule a pick-up for a time that works for you. Our partners will take it from there. You can even watch where your clothes are in the cleaning process with our real-time tracking. And if you have any questions, our customer service team is here to help 24/7.\n \n' +
          'When your clothes are clean, dry, and folded neatly, we\'ll bring them straight to your doorstep. Plus, we make sure to work quickly, so you\'ll get everything back in just a business day or two. Try out Hamperapp today for an easy way to take care of your laundry in San Diego!\n \n'})

      return settings;
  }
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private seoService: SeoService,
    private seoPageService: SeoPageService,
    private errorHandlingService: ErrorHandlingService,
  ) { }

  private resetSEO(): void {
    this.seoService.reset();
  }
}
