import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { WebviewService } from '../webview.service';

@Injectable()
export class WebviewForbiddenGuard implements CanActivate {
  constructor(
    private router: Router,
    private webviewService: WebviewService
  ) { }

  public canActivate(): boolean | UrlTree {
    return !this.webviewService.isWebview || this.router.parseUrl('/login');
  }
}
