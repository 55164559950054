import { BaseEntity } from '@shared/base-entity';
import { Expose, Type } from 'class-transformer';
import { Item } from './item';
import { ItemPivot } from './pivot';

export class ServiceItem extends BaseEntity {
  @Expose()
  @Type(() => Item)
  public item: Item;

  @Expose()
  @Type(() => ItemPivot)
  public pivot: ItemPivot;

  @Expose()
  public get price(): number {
    return this.pivot?.price || this._price;
  }

  public set price(price: number) {
    this._price = price;
  }

  @Expose()
  public get count(): number {
    return this.pivot?.count || this._count;
  }

  public set count(count: number) {
    this._count = count;
  }

  @Expose()
  public get categoryID(): number {
    return this.item?.categoryID || this._categoryID;
  }

  public set categoryID(categoryID: number) {
    this._categoryID = categoryID;
  }

  @Expose()
  public get is_custom(): number {
    return this.item?.is_custom || this._is_custom;
  }
  public set is_custom(is_custom: number) {
    this._is_custom = is_custom || 0;
  }

  @Expose()
  public get imageLink(): string {
    return this.item?.imageLink || this._imageLink;
  }

  @Expose({ name: 'service_id' })
  public serviceID: number;

  public set imageLink(path: string) {
    this._imageLink = path || '';
  }

  @Expose()
  public get title(): string {
    return this.item?.title || this._title;
  }

  public set title(title: string) {
    this._title = title || '';
  }

  public get description(): string {
    return this.item?.description;
  }

  private _imageLink: string;
  private _categoryID: number;
  private _count: number;
  private _price: number;
  private _is_custom: number;
  private _title: string;

  constructor(model: Partial<ServiceItem>) {
    super(model);
    Object.assign(this, model);
  }
}
