import { NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AnimateOnScrollDirective } from '@shared/animate-on-scroll';
import { ButtonComponent } from '@shared/button';
import { ContentComponent } from '@shared/content';
import { IconModule } from '@shared/icon';
import { NgVariableDirective } from '@shared/ng-variable';

@Component({
  standalone: true,
  imports: [
    TranslateModule,
    NgVariableDirective,
    ButtonComponent,
    IconModule,
    RouterLink,
    AnimateOnScrollDirective,
    NgOptimizedImage,
    ContentComponent
  ],
  selector: 'invite-friends',
  templateUrl: 'invite-friends.html',
  styleUrls: ['invite-friends.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InviteFriendsComponent { }
