import {AsyncPipe, isPlatformBrowser, NgFor, NgIf} from '@angular/common';
import { Component, ChangeDetectionStrategy, Input, ViewChild, ElementRef, Inject, PLATFORM_ID, OnInit } from '@angular/core';
import { ActivatedRoute, RouterLinkActive, RouterLink } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MapPipe } from '@shared/map';
import { NgForTrackByPropertyDirective } from '@shared/ng-for-track-by-property';
import { NgVariableDirective } from '@shared/ng-variable';
import { Service } from '@shared/service';
import { compact } from 'lodash';
import { BehaviorSubject, Subject, tap } from 'rxjs';

@UntilDestroy()
@Component({
  standalone: true,
  imports: [
    RouterLink,
    RouterLinkActive,
    NgFor,
    NgForTrackByPropertyDirective,
    AsyncPipe,
    MapPipe,
    NgVariableDirective,
    NgIf
  ],
  selector: 'service-tabs',
  templateUrl: 'service-tabs.html',
  styleUrls: ['service-tabs.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceTabsComponent implements OnInit {
  @ViewChild('tabs', { static: true }) tabsElement: ElementRef<HTMLDivElement>;

  @Input() services: Array<Service>;
  @Input() isShowCommercial:boolean;

  public location$: Subject<{
    code?: string;
    type?: string;
  }>;

  constructor(
    @Inject(PLATFORM_ID) private platformID: object,
    private route: ActivatedRoute
  ) {
    this.location$ = new BehaviorSubject({});
  }

  public ngOnInit(): void {
    this.route.paramMap.pipe(
      tap((paramMap) => {
        this.location$.next({
          code: paramMap.get('locationCode'),
          type: paramMap.get('locationType')
        });
      }),
      untilDestroyed(this)
    ).subscribe();
  }

  public serviceActivated(service: Service): void {
    if (isPlatformBrowser(this.platformID)) {
      const tabIndex = this.services.findIndex((_service) => _service.id === service.id);
      this.tabsElement?.nativeElement?.children?.item(tabIndex)?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center'
      });
    }
  }

  public buildRouterLink(serviceCode: string, location?: {
    code: string;
    type: string;
  }): Array<string> {
    return compact(['/services', serviceCode, location?.type, location?.code]);
  }
}
